<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'

const menuRef = ref()
const route = useRoute()
const isMenuOpen = ref(false)
const isMobile = useIsMobile()
const globalStore = useGlobalStore()

const isLogoVisible = computed(() => !route.meta.noLogo)
const isLinksVisible = computed(() => !route.meta.noLinks)
const isSimpleHeader = computed(() => route.meta.simpleHeader)
const isCartButtonEnabled = computed(() => globalStore.isCheckoutEnabled && route.meta.showCart)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

onClickOutside(menuRef, () => {
  if (!isMenuOpen.value) return

  isMenuOpen.value = false
})

const isShareButtonEnabled = computed(() => route.meta.shareable)
</script>

<template>
  <div ref="menuRef">
    <header
      class="app-header fixed left-0 top-0 z-30 flex h-16 w-full items-center gap-2 rounded-b-lg sm:h-20"
      :class="{ 'drop-shadow-md': !isMenuOpen }"
    >
      <div
        class="m-auto flex h-14 w-full items-center justify-between px-4 py-3 sm:px-10 xl:w-[1280px]"
      >
        <div class="flex gap-4">
          <RAppMenuNavigationButton :is-menu-open="isMenuOpen" @toggle-menu="toggleMenu" />

          <RStoreLogo
            v-if="isLogoVisible"
            class="hidden"
            :class="{
              'md:flex': !isSimpleHeader,
              hidden: isSimpleHeader,
              'fixed left-1/2 top-5 -translate-x-1/2': route.meta.nested,
            }"
            salesperson-class="!text-lg"
          />
        </div>

        <RStoreLogo
          v-if="isLogoVisible"
          class="fixed left-1/2 min-w-[250px] -translate-x-1/2 justify-center md:top-5"
          :class="{ 'md:hidden': !isSimpleHeader }"
        />

        <ClientOnly>
          <div class="flex items-center gap-4">
            <RHeaderLinks v-if="!isMobile && isLinksVisible" />
            <RMenuShareButton v-if="isShareButtonEnabled" class="max-sm:hidden" />
            <RCartButton v-if="isCartButtonEnabled" />
          </div>
        </ClientOnly>
      </div>
    </header>

    <ClientOnly>
      <RBaseDrawer
        :show="isMenuOpen"
        placement="left"
        :title="$t('components.aboutUs.sectionOne.title')"
        class="max-w-[390px] !rounded-l-none !rounded-br-xl"
        with-backdrop
      >
        <div class="max-h-full overflow-auto bg-aqua-haze pb-12">
          <RAboutUs no-title />
        </div>
      </RBaseDrawer>
    </ClientOnly>
  </div>
</template>

<style scoped>
.app-header {
  background-color: var(--color-primary);
  color: var(--color-text);
}
</style>
